<template>
  <router-view />
</template>
<script setup>
import { ref, onMounted, provide } from "vue";
import { initResource } from "@/api/api";
//存储侧边栏icon的URL
const sideBarIcon = ref('');
const adviseVideoUrl = ref('');
const brandName = ref('');  //数字人品牌名称
const companyName = ref('');  //公司名称
const area = ref('');  //公司地址
const kefuPhone = ref('');  //公司地址
const kefuQrcode = ref('');  //公司地址
provide('sideBarIcon', sideBarIcon);
provide('adviseVideoUrl', adviseVideoUrl);
provide('brandName', brandName);
provide('companyName', companyName);
provide('area', area);
provide('kefuPhone', kefuPhone);
provide('kefuQrcode', kefuQrcode);

function isMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|iPhone|iPad|iPod|blackberry|iemobile|opera mini/i.test(userAgent);
}

onMounted(() => {
  if (isMobileDevice()) {
    alert('请在电脑端打开此网页！');
  }
  fetchFaviconAndTitle()
});

async function setFaviconAndTitle(faviconUrl, title) {
  // 设置浏览器标签栏文字
  document.title = title;
  // 设置favicon
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = faviconUrl;
  document.getElementsByTagName('head')[0].appendChild(link);
}

// 尽早地请求favicon链接和标题
function fetchFaviconAndTitle() {
  initResource().then((res) => {
    sideBarIcon.value = res.data.sideBarIcon;
    adviseVideoUrl.value = res.data.adviseVideoUrl;
    brandName.value = res.data.brandName;
    companyName.value = res.data.companyName;
    area.value = res.data.area;
    kefuPhone.value = res.data.kefuPhone;
    kefuQrcode.value = res.data.kefuQrcode;
    localStorage.setItem("companyId", res.data.companyId);
    setFaviconAndTitle(res.data.browserTabIcon, res.data.browserTabTitle);
  })
}

</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-size: 16px;
}
a {
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
input {
  border: none;
  outline: none;
}
</style>
