import { createStore } from 'vuex'

export default createStore({
  state: {
    routePathKey: '',
    partList: [],  //分镜数组
    globalAudioControls: [  //背景音乐
      {
        startTime: '00:00:00.000',
        endTime: '00:00:02.000',
        url: '',
        volume: 0.5,
        musicName: ''
      }
    ],
    isPolling: true,
  },
  getters: {
    getGlobalAudioControls: (state) => state.globalAudioControls
  },
  mutations: {
    saveRoutePathKey(state, pathKey) {
      state.routePathKey = pathKey
    },
    updatePartList(state, updatedPartList) {
      state.partList = updatedPartList;
    },
    // 更新背景音乐的startTime和endTime
    updateStartTimeAndEndTime(state, { startTime, endTime }) {
      state.globalAudioControls[0].startTime = startTime || state.globalAudioControls[0].startTime
      state.globalAudioControls[0].endTime = endTime
    },
    // 更新背景音乐的url和musicName
    updateUrlAndMusicName(state, { url, musicName }) {
      state.globalAudioControls[0].url = url;
      state.globalAudioControls[0].musicName = musicName;
    },
    // 更新背景音乐的volume
    updateVolume(state, volume) {
      state.globalAudioControls[0].volume = volume;
    },
    setIsPolling(state, value) {
      state.isPolling = value;
    },
  },
  actions: {
    updateStartTimeAndEndTime({ commit }, { startTime, endTime }) {
      commit('updateStartTimeAndEndTime', { startTime, endTime });
    },
    updateUrlAndMusicName({ commit }, { url, musicName }) {
      commit('updateUrlAndMusicName', { url, musicName });
    },
    updateVolume({ commit }, volume) {
      commit('updateVolume', volume);
    },
    startPolling({ commit }) {
      commit('setIsPolling', true);
    },
    stopPolling({ commit }) {
      commit('setIsPolling', false);
    },
  },
  modules: {
  }
})
