import { createRouter, createWebHashHistory } from 'vue-router'
import axios from "axios";
import store from '@/store/index.js'
const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('../views/layout/index.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/home/index.vue'),
        meta: {
          pathKey: '1'
        },
      },
      {
        path: '/home/recordList',
        name: 'recordList',
        component: () => import('../views/home/recordList.vue'),
        meta: {
          pathKey: '1'
        },
      },
      {
        path: '/home/memberEquity',
        name: 'memberEquity',
        component: () => import('../views/home/component/memberEquity.vue'),
        meta: {
          pathKey: '1'
        },
      },
      {
        path: '/customMade',
        name: 'customMade',
        component: () => import('../views/customMade/index.vue'),
        meta: {
          pathKey: '2'
        }
      },
      {
        path: '/modelList',
        name: 'modelList',
        component: () => import('../views/modelList/index.vue'),
        meta: {
          pathKey: '3'
        }
      },
      {
        path: '/customMade',
        name: 'customMade',
        component: () => import('../views/customMade/index.vue'),
        meta: {
          pathKey: '2'
        }
      },
      {
        path: '/history',
        name: 'history',
        component: () => import('../views/history/index.vue'),
        meta: {
          pathKey: '4'
        }
      },
      {
        path: '/draft',
        name: 'draft',
        component: () => import('../views/draft/index.vue'),
        meta: {
          pathKey: '5'
        }
      },
      {
        path: '/longText',
        name: 'longText',
        component: () => import('../views/longText/index.vue'),
        meta: {
          pathKey: '6'
        }
      },
    ],
  },
  {
    path: '/makePage/index',
    name: 'makePage',
    redirect: '/makePage/template',
    component: () => import('../views/makePage/index.vue'),
    children: [
      {
        path: '/makePage/template',
        name: 'template',
        component: () => import('../views/makePage/template.vue'),
      },
      {
        path: '/makePage/model',
        name: 'model',
        component: () => import('../views/makePage/model.vue'),
      },
      {
        path: '/makePage/voice',
        name: 'voice',
        component: () => import('../views/makePage/voice.vue'),
      },
      {
        path: '/makePage/sourceMaterial',
        name: 'speed',
        component: () => import('../views/makePage/sourceMaterial.vue'),
      },
      {
        path: '/makePage/background',
        name: 'background',
        component: () => import('../views/makePage/background.vue'),
      },
      {
        path: '/makePage/music',
        name: 'music',
        component: () => import('../views/makePage/music.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('../views/privacyAgreement/privacyPolicy.vue'),
  },
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: () => import('../views/privacyAgreement/userAgreement.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('saveRoutePathKey', to.meta.pathKey)

  // const token = to.query.token; // 从地址栏获取Token
  // if (token) {
  //   localStorage.setItem('authorization', token)
  //   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // 在请求头中添加Token
  // }
  next();
});


export default router
