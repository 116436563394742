import { POST, GET, PUT } from "@/utils/axios";
//获取模板列表
export const digitalTemplate = (params) => GET({ url: "/digitalTemplate/page", params: params });
// 获取历史记录
export const getDigitalAlbum = (params) => GET({ url: "/repair/album?albumType=DIGITAL_HUMAN", params: params });
// 修改历史记录名称
export const updateRecordName = (params) => POST({ url: "/repair/update", params: params });
// 更新模板名称
export const updateTemplateTitle = (params) => POST({ url: "/digitalTemplate/update", params: params });
// 获取模板详情
export const templateDetail = (params) => GET({ url: `/digitalTemplate/detail/${params.id}`, params: params });
// 兑换自定义模板
export const exchangeTemplate = (params) => POST({ url: `/digitalTemplate/exchange?cdkey=${params.cdkey}`, params: params });
//批量删除历史记录
export const deleteVideo = (params) => POST({ url: 'repair/batchDelete', params: params });
// 获取草稿列表
export const getDraft = (params) => POST({ url: "/userDigitalDraft/page", params: params });
// 获取草稿详情
export const getBozzaDetails = (params) => GET({ url: `/userDigitalDraft/detail/${params.id}` });
// 删除草稿
export const deleteDraftIds = (params) => POST({ url: "/userDigitalDraft/delete", params: params });
// 修复接口
export const repairResult = (params) => GET({ url: '/repair/result', params: params });
// 生成数字人
export const aiDigitalV1 = (params) => POST({ url: "/repair/aiDigital", params: params });
// 保存为草稿
export const saveUserDraft = (params) => POST({ url: "/userDigitalDraft/save", params: params });
// 获取音频列表
export const getVoiceList = (params) => GET({ url: "/digitalTemplate/voice/list", params: params });
// 语音试听
export const voiceTts = (params) => POST({ url: "/repair/tts", params: params });
// 保存修改的语音拼音
export const ttsTemporary = (params) => POST({ url: "/repair/ttsTemporary/save ", params: params });
// 获取检测语音的详情
export const ttsTemporaryDetail = (params) => GET({ url: `/repair/ttsTemporary/detail/${params.id}`, params: params });
// 模特场景列表
export const getModelScene = (params) => GET({ url: '/digitalTemplate/modelList', params: params });
// 更新用户操作，目前仅用于更新模特场景为已读
export const updateUserAction = (params) => POST({ url: `/digitalTemplate/updateUserAction?mid=${params.mid}`, params: params });
// 更新自定义信息
export const updateCustom = (params) => POST({ url: "/digitalTemplate/updateCustom", params: params });
// 检测字幕，给汉字带拼音
export const pronounceProcess = (params) => POST({ url: "/repair/pronounceProcess", params: params });
// 创建分片上传任务
export const createMultipart = (params) => POST({ url: "/upload/createMultipart", params: params });
//校验分片上传
export const listParts = (params) => POST({ url: "/upload/listParts", params: params });
// 合并视频
export const operateMultipart = (params) => POST({ url: "/upload/operateMultipart", params: params });
// 上传分片文件
export const uploadVideo = (params) => PUT({ url: `${params.url}`, params: params.params, headers: { "Content-Type": "application/octet-stream", "Authorization": null }, });
//模特选择列表，仅在定制数字人流程页使用
export const modelOptionList = (params) => GET({ url: '/digitalTemplate/modelOptionList', params: params });
// 上传身份证
export const uploadIdcard = (params) => POST({ url: "/video/audio/idcard", params: params });
// 上传背景图
export const uploadBgImg = (params) => POST({ url: "/upload/file", params: params, isMultipart: true });
// 获取用户信息
export const getUserInfo = (params) => GET({ url: '/user/info', params: params });
// 微信登录
export const wxLogin = (params) => POST({ url: "/user/wxLogin", params: params });
// 获取验证码
export const smsCode = (params) => POST({ url: "/user/smsCode", params: params });
// 绑定手机号
export const bindPhone = (params) => POST({ url: "/user/bindPhone", params: params });
// 领取优惠券
export const claimCoupon = (params) => POST({ url: `/coupon/claim/${params.couponId}`, params: params });
// 数字人定制记录
export const makeInfoList = (params) => GET({ url: '/digitalTemplate/makeInfoList', params: params });
// 优惠券列表
export const couponList = (params) => GET({ url: "/coupon/page", params: params });
//订单列表
export const orderList = (params) => GET({ url: "/order/page", params: params });
// 轮询校验支付
export const checkPayResult = (params) => GET({ url: "/order/checkPayResult", params: params });
//微信统一下单
export const unifiedorder = (params) => POST({ url: "/order/unifiedorder", params: params });
//商品列表
export const commodityList = (params) => GET({ url: "/commodity/list", params: params });
// 领取数字人免费时长
export const receiveFreeDuration = (params) => POST({ url: "/activity/receiveFreeDuration", params: params });
// 获取订单详情
export const orderDetail = (params) => GET({ url: `/order/detail/${params.orderId}`, params: params });
// 获取声音分类列表
export const classificationList = (params) => GET({ url: "/digitalTemplate/voice/classificationList", params: params });
// 事件上报接口，埋点
export const eventReport = (params) => POST({ url: "/eventReport", params: params });
// 删除数字人素材
export const deleteSource = (params) => POST({ url: `/digitalTemplate/material/delete/${params.id}`, params: params });
// 获取数字人素材列表，包括背景图、音乐、视频、素材
export const materialList = (params) => GET({ url: "/digitalTemplate/material/list", params: params });
//保存数字人素材
export const saveSource = (params) => POST({ url: "/digitalTemplate/material/save", params: params });
//获取形象卡片列表
export const getImageCard = (params) => GET({ url: "/digitalTemplate/imageCard/list", params: params });
// 剩余时长列表，仅返回未过期的记录
export const getRemainDuration = (params) => GET({ url: "/digitalTemplate/remainDuration/list", params: params });
// 查询身份证号信息，提供提交定制次数
export const idCardInfo = (params) => GET({ url: "/digitalTemplate/idCardInfo", params: params });
// 保存自定义模板
export const saveTemplate = (params) => POST({ url: "/digitalTemplate/save", params: params });
//删除自定义模板
export const deleteTemplate = (params) => POST({ url: `/digitalTemplate/delete/${params.id}`, params: params });
//获取音频
export const vocalSeparate = (params) => POST({ url: '/digitalTemplate/vocalSeparate', params: params });
//获取初始化资源
export const initResource = (params) => GET({ url: "/attribute/initResource", params: params });
// 认领订单
export const orderClaim = (params) => POST({ url: `/order/claim?cdkey=${params.cdkey}`, params: params });
// 手机验证码登录
export const smsLogin = (params) => POST({ url: '/user/smsLogin', params: params });
// 手机密码登录
export const passwordLogin = (params) => POST({ url: '/user/login', params: params });
// 设置登录密码
export const setPassword = (params) => POST({ url: '/user/setPassword', params: params });
