import axios from "axios";
import qs from "qs";
import { ElMessage, ElMessageBox } from 'element-plus'
import { baseURL, loginLink } from './baseURL.js'

const contentType = "application/json;charset=UTF-8";
const instance = axios.create({
  baseURL: baseURL,
  timeout: 50000,
  headers: {
    "Content-Type": contentType,
  },
});
/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    // 这个请求链接不需求传authorization，不然会报参数错误
    if (config.url.indexOf('amemori-s3-cn-northwest-1.s3.cn-northwest-1.amazonaws.com.cn') == -1) {
      config.headers = config.headers || {};
      let authorization = localStorage.getItem("authorization");
      let companyId = localStorage.getItem("companyId");
      config.headers["Oem-Company-Id"] = companyId;
      if (authorization) {
        config.headers["authorization"] = authorization;
      }
    }
    return config;
  },
  (error) => {
    if (error.message.includes('timeout')) {
      ElMessage.error('网络请求超时，请检查网络连接！');
    } else {
      ElMessage.error('网络错误，请检查网络！');
    }
    return Promise.reject(error);
  }
);

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use((response) => {
  if (response.status === 200) {
    if (response.data.code == 2 || response.data.code == 3) {
      localStorage.removeItem('authorization')
      window.location.href = './'
      return;
    }
    if (response.data.code == 0 || response.data.code == 200) {
      return Promise.resolve(response.data);
    } else if (response.data.code == 500) {
      ElMessage.error(response.data.msg || '系统异常！');
      return Promise.reject(response.data);
    } else {
      return Promise.reject(response.data);
    }
  } else {
    ElMessage.error('网络错误，请检查网络！');
    return Promise.reject(response.data);
  }
},
  (error) => {
    if (error.response) {
      // 服务器响应了，但状态码超出 2xx
      ElMessage.error(`请求错误：${error.response.status} ${error.response.statusText}`);
    } else if (error.request) {
      // 请求已发出，但没有收到响应
      ElMessage.error('网络未响应，请检查网络连接！');
    } else {
      // 其他错误
      ElMessage.error(`发生错误：${error.message}`);
    }
    return Promise.reject(error);
  });
/**
 * @description GET
 */
const GET = (data) => {
  return instance({
    ...data,
    method: "get",
    paramsSerializer: {
      serialize: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    // paramsSerializer: (params) =>
    //   qs.stringify(params, { arrayFormat: "repeat" }),
  });
};
/**
 * @description POST
 */
const POST = (data) => {
  if (data.isMultipart) {
    // 如果是原生的上传文件，没有用elementUI
    return instance.post(data.url, data.params, {
      headers: {
        "Content-Type": 'multipart/form-data; boundary=----',
      }
    });
  } else {
    return instance.post(data.url, data.params);
  }
};

/**
 * @description PUT
 */
const PUT = (data) => {
  return instance.put(data.url, data.params, { headers: data.headers });
};

/**
 * @description DELETE
 */
const DELETE = (data) => {
  return instance({
    url: data.url,
    method: "delete",
    data: data.params,
    headers: data.headers,
  });
};

/**
 * @description PATCH
 */
const PATCH = (data) => {
  const config = {
    headers: data.headers,
    serveType: data.serveType,
  };
  return new Promise((resolve, reject) => {
    instance
      .put(data.url, data.params, config)
      .then((res) => {
        if (res && res.status == 200) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { GET, POST, PUT, DELETE, PATCH };
